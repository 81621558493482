<template>
  <div class="corporate-customer-profile">
    <div v-if="loading">Loading</div>
    <template v-else>
      <eden-page-header :title="'Customers'" :subtitle="customerName">
        <template slot="actions">
          <div class="is-flex is-flex-wrap align-center">
            <el-dropdown :placement="'bottom'" @command="actionsCommand">
              <el-button type="plain">
                Actions <i class="eden-icon-arrow-down"></i>
              </el-button>
              <el-dropdown-menu slot="dropdown">
                <template v-for="(action, i) in actions">
                  <el-dropdown-item
                    v-if="allowAccessFor(action.access)"
                    :key="i"
                    :command="action.command"
                    :disabled="action.disabled"
                  >
                    {{ action.label }}</el-dropdown-item
                  >
                </template>
              </el-dropdown-menu>
            </el-dropdown>
            <el-dropdown :placement="'bottom'" @command="actionsCommand">
              <el-button type="primary">
                Subscription <i class="eden-icon-arrow-down"></i>
              </el-button>
              <el-dropdown-menu slot="dropdown">
                <template v-for="(action, i) in subscriptionActions">
                  <el-dropdown-item
                    v-if="allowAccessFor(action.access)"
                    :key="i"
                    :command="action.command"
                    :disabled="action.disabled"
                  >
                    {{ action.label }}</el-dropdown-item
                  >
                </template>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </template>
      </eden-page-header>

      <customer-corporate-profile
        ref="profile"
        :data="customer"
        :subscriptions="subscriptions"
        @wallet-updated="handleWalletUpdate"
      />

      <!-- Dialog and Drawers -->
      <component
        :is="action.component"
        :show.sync="action.visibility"
        :data="customer"
        @success="getCustomer"
        :subscriptions="subscriptions"
      />
      <business-subscription-form
        :show.sync="showSubscriptionForm"
        :company="customer"
        :action="subscription_action"
        :subscriptions="subscriptions"
        @success="setEmployees"
        :menu-type-variation="menuType"
      />
      <corporate-employees-form
        :show.sync="employeeForm"
        :subscriptionId="subscriptionId"
        @success="employeesAdded"
      />
      <business-transaction-form
        :show="showTransactionForm"
        @close="showTransactionForm = false"
      ></business-transaction-form>
      <eden-upload-csv
        :title="csv.title"
        :template-url="csv.templateUrl"
        :fields="csv.fields"
        :url="csv.url"
        :subscriptionId="subscriptionId"
        :show.sync="showBulkEmployeeAdd"
        :uploading.sync="uploading"
        @mapped="upload"
      />

      <subscription-cycle-set
        :show.sync="showSubscriptionCycleSet"
        :customer-email="customerEmail"
        @success="updateChargeDate"
        :customer-type="'corporate'"
      />

      <migrate-employees-subscription
        :show.sync="migrateDialog"
        :subscriptionId="subscriptionId"
        @close="migrateDialog = false"
      ></migrate-employees-subscription>
    </template>
  </div>
</template>

<script>
import Papa from "papaparse";
import business from "@/requests/business";
import subscriptionRequest from "@/requests/subscriptions/subscription";
import MigrateEmployeesSubscription from "../../components/Business/Dialog/MigrateEmployeesSubscription.vue";
import BusinessTransactionForm from "@/components/Subscriptions/Transactions/BusinessTransactionForm.vue";
import CorporateEmployeesForm from "@/components/Business/Employees/CorporateEmployeesForm.vue";
import CustomerCorporateProfile from "@/components/Business/Profile/CustomerCorporateProfile.vue";
import CustomerCorporateInvoiceForm from "@/components/Customers/Corporate/Actions/Invoice/CustomerCorporateInvoiceForm";
import CustomerCorporateOrdersPause from "@/components/Customers/Corporate/Actions/CustomerCorporateOrdersPause";
import CustomerCorporateEmployeesLink from "@/components/Customers/Corporate/Actions/CustomerCorporateEmployeesLink";
import BusinessSubscriptionForm from "@/components/Subscriptions/Subscription/BusinessSubscriptionForm";
import SubscriptionCycleSet from "@/components/Subscriptions/Subscription/SubscriptionCycleSet";
import CustomerCorporateSubscriptionRenewal from "@/components/Customers/Corporate/Actions/CustomerCorporateSubscriptionRenewal";

export default {
  components: {
    CustomerCorporateProfile,
    CustomerCorporateInvoiceForm,
    CustomerCorporateOrdersPause,
    CustomerCorporateEmployeesLink,
    CustomerCorporateSubscriptionRenewal,
    BusinessSubscriptionForm,
    SubscriptionCycleSet,
    CorporateEmployeesForm,
    BusinessTransactionForm,
    MigrateEmployeesSubscription,
  },
  name: "CustomerCorporate",

  data() {
    return {
      uploading: false,
      loading: false,
      employeeForm: false,
      subscriptions: [],
      subscriptionId: "",
      migrateDialog: false,
      migrateEmployees: false,
      showTransactionForm: false,
      showBulkEmployeeAdd: false,
      generate_subscription: false,
      subscription_action: "add",
      csv: {
        title: "Bulk Customers Upload",
        templateUrl:
          "https://docs.google.com/spreadsheets/d/1xUDb0oHE84tohz4w5L-pMWKQifrCAWblv9bV7gYZgRU/edit?usp=sharing",
        fields: ["First Name", "Last Name", "Email"],
        url: `${process.env.VUE_APP_API}customer/bulkupload`,
      },
      actions: [
        {
          icon: "",
          label: "Generate Payment Link",
          command: "generate-payment-link",
          access: ["admin", "gardener", "gardener_pro"],
        },
        {
          icon: "",
          label: "Generate Invoice",
          command: "invoice-form",
          access: ["superadmin", "admin", "growth"],
          disabled: true,
        },
        {
          icon: "",
          label: "Pause/Unpause All Orders",
          command: "orders-pause",
          access: ["superadmin", "admin", "gardener", "gardener_pro"],
        },
        {
          icon: "",
          label: "Link Employee(s)",
          command: "employees-link",
          access: ["admin", "gardener", "gardener_pro"],
        },
        {
          icon: "",
          label: "Edit Profile",
          command: "edit-profile",
          access: ["admin", "gardener", "gardener_pro"],
        },
      ],
      subscriptionActions: [
        {
          icon: "",
          label: "Add subscription",
          command: "add-subscription",
          access: ["superadmin", "admin", "gardener", "gardener_pro"],
          // disabled: true,
        },
        {
          icon: "",
          label: "Renew subscription",
          command: "subscription-renewal",
          access: ["superadmin", "admin", "gardener", "gardener_pro"],
          // disabled: true,
        },
        {
          icon: "",
          label: "View Receipts",
          command: "uploaded-receipts",
          access: ["superadmin", "admin", "gardener", "gardener_pro"],
          // disabled: true,
        },
        {
          icon: "",
          label: "Generate subscription",
          command: "generate-subscription",
          access: ["superadmin", "admin", "gardener", "gardener_pro"],
          // disabled: true,
        },
        {
          icon: "",
          label: "Edit subscription",
          command: "subscription-form",
          access: ["superadmin", "admin", "gardener_pro"],
        },
        {
          icon: "",
          label: "Add Transacttion",
          command: "add-transaction",
          access: ["superadmin", "admin", "gardener_pro"],
        },
        {
          icon: "",
          label: "Set Cycle",
          command: "subscription-cycle-set",
          access: ["admin", "gardener", "gardener_pro"],
        },
        {
          icon: "",
          label: "Update amount",
          command: "amount",
          access: ["superadmin", "admin", "gardener_pro"],
        },
      ],
      action: {
        command: "",
        component: "",
        visibility: false,
      },
      tab: "overview",
      customer: {},
      showSubscriptionForm: false,
      showSubscriptionCycleSet: false,
    };
  },
  computed: {
    customerId() {
      return this.$route.params.id;
    },
    customerName() {
      return this.customer.company_name
        ? this.formatName(this.customer.company_name)
        : "";
    },
    customerEmail() {
      return this.customer.admins[0].email?.toLowerCase();
    },
    menuTypeVariation() {
      if (this.customer.menu_type_variation_id === null) {
        return {};
      }
      let menus = this.$store.getters.menu_variations;
      return {
        employee: "fixed",
        menuOptions: menus,
      };
    },
    customerSubscription() {
      const subscription =
        this.customer.next_billing_date?.length &&
        this.customer.next_billing_date[0];
      console.log(subscription);
      return {};
      // return {
      //   id: this.customer.subscription_id,
      //   name: this.formatName(this.customer.company_name),
      //   email: this.customer.contact_person_email_address?.toLowerCase(),
      //   status: "active",
      //   plan: this.customer.plan,
      //   services: Object.keys(this.customer.plan),
      //   amount: this.customer.subscription_amount,
      //   start_date: this.customer.start_date,
      //   next_charge_date: this.customer.next_charge_date,
      //   menu_type: this.menuTypeVariation,
      // };
    },
    menuType() {
      return this.customerSubscription.menu_type;
    },
  },
  created() {
    this.getSubscription();
    this.getCustomer();
  },
  methods: {
    handleWalletUpdate(updatedData) {
      this.customer = updatedData;
    },

    setEmployees(data) {
      this.migrateEmployees = data.migrateEmployees;
      this.subscriptionId = data.subscription.id;
      if (data.uploadType == "Single") {
        this.employeeForm = true;
      } else {
        this.showBulkEmployeeAdd = true;
      }
    },

    employeesAdded() {
      this.employeeForm = false;
      this.getSubscription();
      if (this.migrateEmployees) {
        this.migrateDialog = true;
      }
    },

    getCustomer() {
      this.loading = true;
      business
        .get(this.customerId)
        .then((response) => {
          this.customer = response.data.data;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },

    getSubscription() {
      subscriptionRequest
        .getCompanySubscriptions(this.customerId)
        .then((response) => {
          let { data } = response.data;
          this.subscriptions = data;
          this.$store.commit("SAVE_SUBSCRIPTION", data);
        })
        .catch((error) => {
          this.loading = false;
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },

    actionsCommand(command) {
      if (command === "amount") {
        this.$refs.profile.$refs.employees.updateSubscriptionAmount();
        return;
      }
      if (command === "subscription-cycle-set") {
        this.showSubscriptionCycleSet = true;
        return;
      }
      // if (command === "subscription-renewal") {
      //   this.showSubscriptionCycleSet = true;
      //   return;
      // }

      if (command === "generate-subscription") {
        this.showBulkEmployeeAdd = true;
        this.generate_subscription = true;
        this.subscriptionId = "1234";
        return;
      }

      if (command === "add-transaction") {
        this.showTransactionForm = true;
        return;
      }

      if (command === "add-subscription") {
        this.subscription_action = "add";
        this.showSubscriptionForm = true;
        return;
      }
      if (command === "uploaded-receipts") {
        this.$router.push({
          name: "business.uploaded-receipts",
          params: { id: this.$route.params.id },
        });
        return;
      }
      if (command === "subscription-form") {
        this.subscription_action = "edit";
        this.showSubscriptionForm = true;
        return;
      }
      if (command === "edit-profile") {
        this.$router.push({
          name: "customers.corporate-edit",
          params: { id: this.customerId },
        });
        return;
      }
      this.action.command = command;
      this.action.component = `customer-corporate-${command}`;
      setTimeout(() => {
        this.action.visibility = true;
      }, 200);
    },
    actionsUpdate(data) {
      const { command } = this.action;
      switch (command) {
        case "status-change":
          this.updateStatus(data);
          break;
        case "subscription-cycle-set":
          this.updateChargeDate(data);
          break;
        default:
          break;
      }
    },
    upload(csv) {
      if (this.generate_subscription) {
        this.generateSubscription(csv);
      } else {
        this.uploadCustomers(csv);
      }
    },

    uploadCustomers(csv) {
      const file = this.convertUploadBackToFile(csv);

      const formData = new FormData();
      formData.append("csv", file);
      business
        .addEmployeesBulk({ id: this.subscriptionId, payload: formData })
        .then((response) => {
          if (response.data.status) {
            this.uploading = false;
            this.showBulkEmployeeAdd = false;
            this.$message.success(response.data.message);
            if (this.migrateEmployees) {
              this.migrateDialog = true;
            }
            // this.updateEmployeesList();
          }
        })
        .catch((error) => {
          this.uploading = false;
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },
    generateSubscription(csv) {
      const file = this.convertUploadBackToFile(csv);
      const formData = new FormData();
      formData.append("csv", file);
      business
        .generatesubscription({ id: this.$route.params.id, payload: formData })
        .then((response) => {
          if (response.data.status) {
            this.uploading = false;
            this.showBulkEmployeeAdd = false;
            this.$message.success(response.data.message);
            this.generate_subscription = false;
            // this.updateEmployeesList();

            this.getSubscription();
            this.getCustomer();
          }
        })
        .catch((error) => {
          this.uploading = false;
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },

    convertUploadBackToFile(csv) {
      csv.splice(0, 1);

      const csvArrayOfObject = csv.map((val) => ({
        first_name: val[0],
        last_name: val[1],
        email: val[2],
      }));

      let file = new File([Papa.unparse(csvArrayOfObject)], "filename01", {
        type: "text/plain",
      });

      return file;
    },

    updateStatus(status) {
      this.customer.customer_status = status
        ? status
        : this.customer.customer_status;
    },

    updateChargeDate(date) {
      this.customer.next_charge_date = date;
    },
  },
};
</script>

<style lang="scss" scoped>
$-summary-width: 320px;

.individual-customer-profile {
  .body {
    margin-top: 45px;

    .detail {
      margin-left: 64px;
      width: calc(100% - #{$-summary-width});
    }
  }

  @media (max-width: 992px) {
    .body {
      flex-wrap: wrap;

      .detail {
        margin: 0;
        width: 100%;
      }
    }
  }
}
</style>
