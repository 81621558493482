<template>
    <div class="account-admins_list">
        <el-dialog title="Account Admins" :visible.sync="shouldShow" width="60%" @close="$emit('close')">
            <Section>
                <div class="is-flex flex-wrap gap-20">
                <admin-card :adminProfile="admin" :avatarTheme="selectTheme(index + 1)"
                    v-for="(admin, index) in accountAdmins" :key="admin.name"></admin-card>
            </div>
            </Section>
          
        </el-dialog>
    </div>
</template>

<script>
import AdminCard from "./AdminCard.vue";
export default {
    props: {
        shouldShow: {
            type: Boolean
        },
        accountAdmins: {
            type: Array
        }
    },
    data() {
        return {
            dialogVisible: true,
            avatarThemesOprions: {
                purple: {
                    color: "#5571FF",
                    background: "#E5E9FC"
                },
                green: {
                    color: "#03A84E",
                    background: "#E9FBF2"
                },
                pink: {
                    color: "#E93896",
                    background: "#FBE7F2"
                }
            }
        };
    },
    methods: {

        selectTheme(idx) {
            if (idx > 1 && idx % 3 == 0) {
                return this.avatarThemesOprions.pink
            }
            if (idx > 1 && idx % 2 == 0) {
                return this.avatarThemesOprions.green
            }
            return this.avatarThemesOprions.purple
        }
    },

    components: {
        AdminCard
    }
};
</script>

<style lang="scss" scoped>
.account-admins_list {
    ::v-deep .el-dialog {
        width: 50% !important;
    }
}
</style>