<template>
  <el-drawer :title="title" :visible.sync="shouldShow" direction="rtl" @close="closeEvent">
    <div class="el-drawer--content">
      <div class="el-drawer--content__body">
        <el-form :model="form" label-position="top" ref="form">
          <el-row type="block">
            <el-col :md="24" v-if="action == 'edit'">
              <el-form-item label="Subscription" prop="subscription_id" :rules="validateField()">
                <el-select v-model="form.subscription_id" placeholder="Select subscription">
                  <el-option v-for="sub in subscriptions" :key="sub.id" :label="sub.name" :value="sub.id"></el-option>
                </el-select>
              </el-form-item>
            </el-col>

            <el-col :span="24">
              <el-form-item label="Subscription name" prop="name" :rules="validateName()">
                <el-input type="text" v-model="form.name" placeholder="Enter subscription name" />
              </el-form-item>
            </el-col>
          </el-row>
          <template v-if="action !== 'edit' || allowAccessFor(['superadmin', 'admin'])">
            <el-row type="flex">
              <el-col :span="24">
                <subscription-configuration :config="form.plan" @set-config="setPlan"
                  :menu-type-variation="menuTypeVariation" />
              </el-col>
            </el-row>
          </template>
          <el-row type="flex">
            <el-col :span="24">
              <el-form-item label="Subscription Amount" prop="amount">
                <el-input v-model="form.amount" v-number readonly />
              </el-form-item>
            </el-col>
          </el-row>
          <template v-if="action !== 'edit'">
            <el-row type="flex">
              <el-col :span="24">
                <el-form-item label="Start date" prop="start_date" :rules="validateField()">
                  <el-date-picker v-model="form.start_date" type="date" format="dd MMM, yyyy" value-format="yyyy-MM-dd"
                    placeholder="Select start date">
                  </el-date-picker>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row type="flex">
              <el-form-item label="Select method of adding employees to this subscription">
                <el-radio-group v-model="uploadType" :max="1" class="eden-custom-pause--radio mb-3 w-100 is-flex">
                  <el-radio-button :label="item" v-for="item in employeeUploadTypes" :key="item" class="w-100">{{ item }}
                  </el-radio-button>
                </el-radio-group>
              </el-form-item>
            </el-row>

            <el-form-item label="Do you want migrate this employees">
              <el-switch v-model="form.migrate" :active-value="true" :inactive-value="false">
              </el-switch>
            </el-form-item>
            <!-- <el-row v-else type="flex">
              <el-col :span="24">
                <el-form-item label="Next charge date" prop="next_charge_date" :rules="validateField()">
                  <el-date-picker v-model="form.next_charge_date" type="date" format="dd MMM, yyyy"
                    value-format="yyyy-MM-dd" placeholder="Select next charge date">
                  </el-date-picker>
                </el-form-item>
              </el-col>
            </el-row> -->
          </template>

        </el-form>
      </div>
    </div>

    <div class="el-drawer--footer is-align-center">
      <el-button v-if="action === 'edit'" type="primary" :loading="updating" @click="update"
        :disabled="invalidSelection">Save changes</el-button>
      <el-button v-else type="primary" :loading="adding" @click="add"
        :disabled="invalidSelection || !uploadType.length">Configure subscription</el-button>
    </div>
  </el-drawer>
</template>
  
<script>
import subscriptions from "@/requests/business/subscription";

export default {
  name: "SubscriptionForm",
  props: {
    action: {
      type: String,
      default: "add",
    },
    show: {
      type: Boolean,
      default: false,
    },
    company: {
      type: Object,
      default() {
        return {};
      },
    },
    subscriptions: {
      type: Array,
      default() {
        return [];
      },
    },
    subscription: {
      type: Object,
      default() {
        return {};
      },
    },
    menuTypeVariation: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      adding: false,
      updating: false,
      customers: [],
      form: {
        name: "",
        email: "",
        amount: 0,
        services: [],
        plan: {},
        start_date: "",
        subscription_id: "",
        next_charge_date: "",
      },
      invalidSelection: false,
      uploadType: '',
      employeeUploadTypes: ["Single", 'Bulk']
    };
  },
  computed: {
    shouldShow: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit("update:show", value);
      },
    },
    title() {
      return this.action !== "edit" ? "Add Subscription" : "Edit Subscription";
    },
    services() {
      return this.$store.getters.services;
    },
  },
  watch: {
    show() {
      if (this.show) {
        // if (this.action === "add") {
        //   this.form.name = this.formatName(this.customer.name);
        //   this.form.email = this.stringTrim(this.customer.email);
        // } else {
        //   this.form = JSON.parse(JSON.stringify(this.subscription));
        // }
      }
    },
    form: {
      deep: true,
      handler() {
        for (let service in this.form.plan) {
          this.invalidSelection = !this.form.plan[service].amount;
        }
      },
    },

    "form.subscription_id"(newval) {
      let subscription = this.subscriptions.find((elm) => elm.id == newval)
      if (subscription) {
        this.form = { ...subscription, subscription_id: newval }
      }
    }
  },
  methods: {
    closeEvent() {
      this.shouldShow = false;
      this.$refs.form.resetFields();
      this.form.services = [];
      this.form.plan = {};
    },
    setPlan({ config, amount }) {
      let service = Object.keys(config)[0];
      if (service === "meal") {
        this.form.plan = config;
        // this.form.plan = {meal: config[service]}
        // this.form.menu_type = config.menu_type;
        // this.form.menu_type_variation_id = config.menu_type.id
        // } else {
        //   this.form.plan = config;
      }
      this.form.amount = amount;
    },
    disabledDates(time) {
      if (this.allowAccessFor([])) {
        return false;
      }
      return (
        time.getTime() < new Date(new Date().getTime() + 24 * 60 * 60 * 1000)
      );
    },
    formatForm() {
      const payload = { ...this.form };
      let menuType = this.$store.getters.menu_variations.find(
        (elm) => elm.id == this.form.plan.meal.menu_type_variation_id,
      );
      if (menuType) {
        // payload.name = menuType.name;
        payload.menu_type_variation_id = menuType.id;
      }
      const sortedPlan = this.sortObject(payload.plan);
      payload.menu_type_variation_id;
      payload.corporate_id = Number(this.$route.params.id)
      payload.plan = sortedPlan;
      payload.admin_email = this.company.admins[0]?.email
      payload.new_onboarding_flow = true;
      delete payload.services;
      delete payload.menu_type
      return payload;
    },
    add() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) {
          return false;
        }
        this.adding = true;
        const payload = this.formatForm();
        subscriptions
          .create({ id: this.$route.params.id, payload })
          .then((response) => {
            if (response.data.status) {
              this.$message.success(response.data.message);
              this.adding = false;
              this.$emit("success", { uploadType: this.uploadType, subscription: response.data.data, migrateEmployees: this.form.migrate });
              this.closeEvent();
            } else {
              this.$message.error(response.data.message);
              this.adding = false;
            }
          })
          .catch((error) => {
            this.adding = false;
            const errorMessage = error.response.data;
            if (errorMessage.errors) {
              const errorKeys = Object.keys(errorMessage.errors);
              this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
            } else {
              this.$message.error(errorMessage.message);
            }
          });
        return true;
      });
    },

    update() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) {
          return false;
        }
        this.updating = true;
        const payload = this.formatForm();
        subscriptions
          .update({ id: this.form.subscription_id, payload })
          .then((response) => {
            if (response.data.status) {
              this.$message.success(response.data.message);
              this.updating = false;
              this.$emit("updated");
              this.closeEvent();
            } else {
              this.$message.error(response.data.message);
              this.updating = false;
            }
          })
          .catch((error) => {
            this.updating = false;
            const errorMessage = error.response.data;
            if (errorMessage.errors) {
              const errorKeys = Object.keys(errorMessage.errors);
              this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
            } else {
              this.$message.error(errorMessage.message);
            }
          });
        return true;
      });
    },
  },
};
</script>
  
<style lang="scss" scoped>
.heading {
  margin: 20px 0 10px;

  p {
    font-size: 1rem;
    font-weight: 500;
    color: #21312a;
  }
}

::v-deep(.el-radio-button) {
  span {
    width: 100%;
  }
}

.eden-plan--summary {
  width: 100%;
  background: rgba(0, 77, 41, 0.04);
  border-radius: 8px;
  padding: 20px;

  >div {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }

  span {
    color: #798b83;
    width: 50%;
  }

  p {
    color: #21312a;
    font-size: 1rem;
  }
}

.eden-customer-service {
  margin-bottom: 40px;
}
</style>