<template>
  <div class="corporate-employees">
    <eden-table-actions :title="`${ total } Employees`" @search="search">
      <template slot="actions">
        <template v-if="selectedEmployees.length">
          <div class="is-flex is-align-center">
            <el-button type="primary" @click="showEmployeePauses = true">
              Pause/Unpause Orders
            </el-button>
          </div>

          <div class="is-flex is-align-center mx-2">
            <el-button type="primary" @click="showEmployeeFundWallet = true">
              Fund Wallet
            </el-button>
          </div>
        </template>
        <div class="is-flex align-center justify-end">
          <el-button type="plain" icon="eden-icon-upload" :disabled="loading || !employees.length"
            @click="exportEmployees">
            Export
          </el-button>

          <el-dropdown class="dropdown-with-buttons" @command="addEmployee">
            <el-button type="primary">
              Add Employee
              <i class="el-icon-arrow-down"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="single" icon="el-icon-circle-plus-outline">Single employee
              </el-dropdown-item>
              <el-dropdown-item command="bulk" icon="eden-icon-upload">Bulk upload
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </template>
    </eden-table-actions>

    <template v-if="loading">Loading...</template>
    <el-table v-else style="width: 100%" :data="employeeData" @selection-change="setSelectedEmployees">
      <el-table-column type="selection" :selectable="isNotChurned" width="55">
      </el-table-column>
      <el-table-column width="200">
        <template slot="header">
          <div class="table--header">
            <span>Name</span>
          </div>
        </template>
        <template slot-scope="scope">
          <router-link :to="{
            name: 'customers.individual',
            params: { id: scope.row.id },
          }">
            <span class="font-sm text-primary">
              {{ formatName(scope.row.name) }}</span></router-link>
          <span class="font-xsm lowercase">{{ scope.row.email }}</span>
        </template>
      </el-table-column>
      <el-table-column>
        <template slot="header">
          <span class="eden-table-header"> Phone </span>
        </template>
        <template slot-scope="scope">
          <span class="font-sm">
            {{ formatPhoneNumber(scope.row.phone_number) }}
          </span>
        </template>
      </el-table-column>
      <el-table-column>
        <template slot="header">
          <div class="table--header">
            <span>Status</span>
          </div>
        </template>
        <template slot-scope="scope">
          <span class="font-sm table--status">
            <el-tag :type="setType(scope.row.customer_status)">
              {{ formatText(scope.row.customer_status) }}
            </el-tag>
          </span>
        </template>
      </el-table-column>
      <!-- <el-table-column>
        <template slot="header">
          <div class="table--header">
            <span>Services</span>
          </div>
        </template>
        <template slot-scope="scope">
          <eden-services-list
            v-if="scope.row.services.length"
            :services="scope.row.services"
          />
          <span v-else>-</span>
        </template>
      </el-table-column> -->
      <el-table-column>
        <template slot="header">
          <div class="table--header">
            <span>Gardener</span>
          </div>
        </template>
        <template slot-scope="scope">
          <span class="font-sm">{{
            scope.row.gardener || "No assigned gardener"
          }}</span>
        </template>
      </el-table-column>
      <el-table-column>
        <template slot="header">
          <div class="table--header">
            <span>Zone/Location Area</span>
          </div>
        </template>
        <template slot-scope="scope">
          <span class="font-sm">{{ scope.row.location_area }}</span>
          <span class="font-xsm">{{ scope.row.cluster || "-" }}</span>
        </template>
      </el-table-column>
      <el-table-column v-if="allowAccessFor(['superadmin'])" width="60">
        <template slot-scope="scope">
          <el-dropdown @command="command" class="more">
            <span class="el-dropdown-link">
              <i class="eden-icon-more" />
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item icon="el-icon-document" v-if="allowAccessFor(['superadmin', 'gardener_pro', 'gardener'])" :command="{
                action: 'fund-wallet',
                id: scope.row.id,
                index: scope.$index,
                customer: scope.row,
              }">Fund Wallet</el-dropdown-item>
              <el-dropdown-item icon="eden-icon-delete" :command="{
                action: 'delete',
                id: scope.row.id,
                index: scope.$index,
                customer: scope.row,
              }">Delete Employee</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </template>
      </el-table-column>
    </el-table>
    <eden-pagination 
      v-if="showPagination" 
      :from="from" 
      :to="to"
      :total="total" 
      :pageSize="per_page"
      :current-page.sync="page"
    />
    <corporate-employees-export 
      :show.sync="exporting" 
      :companyName="company.company_name" 
      :employees="employees"
      :data-time="pageDataTime" 
    />
    <corporate-employees-form 
      :show.sync="employeeForm" 
      :company="company" 
      :subscriptions="subscriptions"
      @success="updateEmployeesList" 
    />
    <customer-corporate-orders-pause 
      :show.sync="showEmployeePauses" 
      :data="this.company" 
      :employees="selectedEmployees"
      @success="getPauses" 
    />
    <eden-upload-csv 
      :title="csv.title" 
      :template-url="csv.templateUrl" 
      :fields="csv.fields" :url="csv.url"
      :subscriptions="subscriptions" :show.sync="showBulkEmployeeAdd" :uploading.sync="uploading"
      @mapped="uploadCustomers" @saveSubscriptionId="subscriptionId = $event" 
    />

    <customer-employees-delete 
    :show.sync="showCustomerCorporateDelete" 
    :customer="employeeDetails.data"
      @success="updateEmployees" />
    <employees-fund-wallet :show.sync="showEmployeeFundWallet" :data="this.company" :customer="employeeDetails.data"
      :employees="selectedEmployees" @wallet-updated="$emit('wallet-updated', $event)" />
  </div>
</template>

<script>
import Papa from "papaparse";
import business from "@/requests/business";
import subscriptions from "@/requests/subscriptions";
import * as actions from "@/store/action-types";
import CorporateEmployeesExport from "@/components/Business/Employees/CorporateEmployeesExport.vue";
import CorporateEmployeesForm from "@/components/Business/Employees/CorporateEmployeesForm.vue";
import CustomerCorporateOrdersPause from "@/components/Customers/Corporate/Actions/CustomerCorporateOrdersPause";
import CustomerEmployeesDelete from "../Actions/CustomerEmployeesDelete";
import EmployeesFundWallet from "@/components/Business/Employees/FundWallet/EmployeesFundWallet";

export default {
  components: {
    CustomerEmployeesDelete,
    CorporateEmployeesExport,
    CorporateEmployeesForm,
    CustomerCorporateOrdersPause,
    EmployeesFundWallet,
  },
  name: "CustomerCorporateEmployees.vue",
  props: {
    company: {
      type: Object,
      default() {
        return {};
      },
    },
    subscriptions: {
      type: Array,
      default() {
        return [];
      },
    },
    customerType: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      loading: false,
      uploading: false,
      page: 1,
      showPagination: false,
      employeeData: [],
      employeeId: "",
      employee: {},
      customer: {},
      exporting: false,
      searchText: "",
      subscriptionId: '',
      employeeForm: false,
      showEmployeeFundWallet: false,
      showBulkEmployeeAdd: false,
      showCustomerCorporateDelete: false,
      pageDataTime: "",
      selectedEmployees: [],
      showEmployeePauses: false,
      employeeDetails: {
        action: "delete",
        visibility: false,
        data: {},
      },
      csv: {
        title: "Bulk Customers Upload",
        templateUrl:
          "https://docs.google.com/spreadsheets/d/1xUDb0oHE84tohz4w5L-pMWKQifrCAWblv9bV7gYZgRU/edit?usp=sharing",
        fields: [
          "First Name",
          "Last Name",
          "Email",
        ],
        url: `${process.env.VUE_APP_API}customer/bulkupload`,
      },
    };
  },

  watch:{
    page() {
        const pageFetched = !!this.employees.pages[this.page];
        if (pageFetched) {
          this.setPageData();
        } else {
          this.getEmployees();
        }
      }
  },
  computed: {
    companyId() {
      return this.$route.params.id;
    },
    employees() {
      return this.$store.state.business.employees;
    },
    from() {
      return this.employees.pages[this.page]?.from;
    },
    to() {
      return this.employees.pages[this.page]?.to;
    },
    total() {
      return this.employees.total;
    },
    per_page() {
      return this.employees.per_page;
    },
    bulkUploadUrl() {
      return `${process.env.VUE_APP_API}crm/customer/business/subscripdions/${this.subscriptionId}/employees/bulk`
    },
  },
  created() {
    this.getEmployees();
  },
  mounted() {
    // this.employeeData = this.employeeData.map((employee) =>
    //   this.formatToTitleCase(employee.name),
    // );
  },
  methods: {
    updateCustomerData(updatedData) {
      this.employeeDetails.data = updatedData; // To fetch the updated data again, if needed
    },
    getEmployees() {
      this.loading = true;
      this.$store.dispatch(actions.GET_EMPLOYEES, {id:this.$route.params.id, page:this.page})
        .then(() => {
          this.loading = false;
          this.setPageData()
          this.pageDataTime = new Date();
        })
        .catch((error) => {
          this.loading = false;
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },
    search(query) {
        if (query === "") {
          this.setPageData();
          return;
        }
  
        this.loading = true;
        this.searchText = query;
        let id = this.$route.params.id
        business
          .searchEmployyee(id,query)
          .then((response) => {
            this.employeeData = response.data.data.data;
            this.showPagination = false;
            this.loading = false;
          })
          .catch((error) => {
            this.loading = false;
            const errorMessage = error.response.data;
            if (errorMessage.errors) {
              const errorKeys = Object.keys(errorMessage.errors);
              this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
            } else {
              this.$message.error(errorMessage.message);
            }
          });
      },
    getLocationAreaId(locationAreaName) {
      const locations = this.$store.getters.location_areas_list;
      const location = locations.filter(
        (location) =>
          location.name.split(" ").join("-").toLowerCase() ===
          locationAreaName.split(" ").join("-").toLowerCase(),
      );
      if (location.length) {
        return location[0].id;
      } else {
        return process.env.NODE_ENV === "production" ? 122 : 944;
      }
    },
    uploadCustomers(csv) {
      csv.splice(0, 1);

      const csvArrayOfObject = csv.map((val) => ({
        "first_name": val[0],
        "last_name": val[1],
        "email": val[2],
      }));

      var file = new File([Papa.unparse(csvArrayOfObject)], "filename01", {
        type: "text/plain",
      });
      const formData = new FormData();
      formData.append("csv", file);

      business
        .addEmployeesBulk({ id: this.subscriptionId, payload: formData })
        .then((response) => {
          if (response.data.status) {
            this.uploading = false;
            this.showBulkEmployeeAdd = false;
            this.$message.success(response.data.message);
            // this.updateEmployeesList();
          }
        })
        .catch((error) => {
          this.uploading = false;
          const errorMessage = error.response ? error.response.data : error;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },
    exportEmployees() {
      this.exporting = true;
    },
    setPageData() {
        this.employeeData = this.employees.pages[this.page].data;
        this.showPagination = true;
      },
    addEmployee(command) {
      if (command === "bulk") {
        this.showBulkEmployeeAdd = true;
        this.$store.dispatch(actions.GET_LOCATION_AREAS_LIST);
      } else {
        this.employeeForm = true;
      }
    },
    updateEmployeesList() {
      this.getEmployees(this.updateSubscriptionAmount);
    },
    updateSubscriptionAmount() {
      const subscriptionId = this.company.subscription_id;
      const subscriptionAmount = this.employees
        .filter((employee) => employee.customer_status === "subscriber")
        .reduce((total, employee) => {
          const employeeSubscriptionAmount = employee.next_billing_date
            ? employee.next_billing_date[0].amount
            : 0;
          return total + employeeSubscriptionAmount;
        }, 0);

      const payload = {
        amount: subscriptionAmount,
      };

      subscriptions
        .update(subscriptionId, payload)
        .then((response) => {
          if (response.data.status) {
            this.$message.success(response.data.message);
          }
        })
        .catch((error) => {
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },
    updateEmployees() {
      this.employees.splice(this.employee.index, 1);
      this.updateSubscriptionAmount();
    },
    setSelectedEmployees(employees) {
      this.selectedEmployees = [];
      employees.forEach((employee) => {
        this.selectedEmployees.push(employee.id);
      });
    },
    
    isNotChurned(row) {
      return row.customer_status !== "churned";
    },
    command(command) {
      switch (command.action) {
        case "add":
        case "fund-wallet":
          this.employeeDetails.data = this.employees[command.index];
          this.showEmployeeFundWallet = true;
          break;
        case "delete":
          this.employeeDetails.data = this.employees[command.index];
          this.showCustomerCorporateDelete = true;
          break;
        default:
          break;
      }
    },
    getPauses() {
      this.getEmployees();
    },
  },
};
</script>
