<template>
    <el-dialog title="Migrate Employees" :visible.sync="setShow" @close="closeEvent" width="30%">
        <el-form :model="form" label-position="top" ref="form">
            <el-row type="flex">
                <el-col :span="24">
                    <el-form-item label="Subscription Start Date" prop="start_date" :rules="validateField()">
                        <el-date-picker placeholder="Start" v-model="form.start_date" value-format="yyyy-MM-dd" type="date"
                            format="dd MMM, yyyy" prefix-icon="eden-icon-calendar" />
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button plain @click="closeEvent">Cancel</el-button>
            <el-button type="primary" :loading="loading" @click="submit">Migrate</el-button>
        </span>
    </el-dialog>
</template>
  
<script>
import subscription from "@/requests/business/subscription";

export default {
    name: "SubscriptionCycleSet",
    props: {
        show: {
            type: Boolean,
            default: false,
        },
        subscriptionId:{
            type: [String, Number]
        }
    },
    data() {
        return {
            loading: false,
            form: {
                start_date: "",
            },
        };
    },
    computed: {
        setShow: {
            get() {
                return this.show;
            },
            set(value) {
                this.$emit("update:show", value);
            },
        },
    },
    methods: {
        closeEvent() {
            this.setShow = false;
            this.$refs.form.resetFields();
        },
        disableStartDate(time) {
            if (this.allowAccessFor(["superadmin"])) {
                return false;
            }

            return time.getTime() <= new Date(this.form.start_date).getTime();
        },

        submit() {
            this.loading = true;
            subscription.merge_subscriptions(this.subscriptionId, this.form.start_date)
                .then((response) => {
                    this.$message.success(response.data.message);
                    this.loading = false;
                    this.closeEvent()
                }).catch((error) => {
                    this.loading = false;
                    const errorMessage = error.response.data;
                    if (errorMessage.errors) {
                        const errorKeys = Object.keys(errorMessage.errors);
                        this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
                    } else {
                        this.$message.error(errorMessage.message);
                    }
                })
        }

    },
};
</script>
  
<style lang="scss" scoped>
.note {
    margin: 10px 0;

    span {
        font-weight: bold;
    }
}
</style>
  