<template>
  <div>
    <el-drawer :visible.sync="shouldShow" direction="rtl" @close="closeEvent">
      <template slot="title">
        <span class="font-md text-grey-tertiary">Add Employee</span>
      </template>
      <div class="el-drawer--content" v-loading="loading">
        <div class="el-drawer--content__body">
          <el-form :model="form[0]" label-position="top" ref="form">
            <el-row type="flex" :gutter="15" class="is-flex-wrap">
              <el-col :md="24">
                <el-form-item
                  label="Select subscription"
                  :rules="validateField()"
                >
                  <el-select
                    v-model="subscription_id"
                    filterable
                    placeholder="Select subscription"
                  >
                    <el-option
                      v-for="sub in subscriptions"
                      :key="sub.id"
                      :label="sub.name"
                      :value="sub.id"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row
              type="flex"
              :gutter="15"
              class="is-flex-wrap"
              v-for="(formItem, index) in form"
              :key="index"
            >
              <el-col :span="1" v-if="deleteEnabled">
                <div>
                  <el-checkbox v-model="formItem.deleteSelected" />
                </div>
              </el-col>
              <el-col :span="deleteEnabled ? 23 : 24">
                <el-col :span="24" v-if="form.length > 1">
                  <p>Employes {{ index + 1 }}</p>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="First name" :rules="validateName()">
                    <el-input
                      type="text"
                      v-model="formItem.first_name"
                      placeholder="Enter first name"
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="Last name" :rules="validateName()">
                    <el-input
                      type="text"
                      v-model="formItem.last_name"
                      placeholder="Enter last name"
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="24">
                  <el-form-item label="Email address">
                    <el-input
                      type="text"
                      v-model="formItem.email"
                      placeholder="you@mail.com"
                    ></el-input>
                  </el-form-item>
                </el-col>
              </el-col>
            </el-row>
            <el-row type="flex" justify="start" class="mt-0">
              <el-col :span="5" class="mt-0">
                <el-button
                  type="text"
                  @click="addRow"
                  class="is-flex font-sm text-primary"
                >
                  <span class="ml-1">Add row</span>
                </el-button>
              </el-col>
              <el-col :span="17" class="mt-0">
                <div v-if="form.length > 1">
                  <el-button
                    v-if="!deleteEnabled"
                    class="px-2 py-2 text-4xl align-self-start"
                    @click="deleteEnabled = true"
                  >
                    <i
                      class="eden-icon-delete text-3xl text-danger pointer font-standalone"
                    />
                  </el-button>
                  <div v-else class="is-flex is-align-center">
                    <div class="mr-1" v-if="noItemToDelete">
                      Select the rows you want to delete.
                    </div>
                    <el-button
                      v-else
                      type=""
                      class="px-2 py-2 text-4xl text-danger"
                      @click="deleteRows"
                    >
                      <i
                        class="eden-icon-delete text-3xl mr-1 text-danger pointer font-standalone"
                      />
                      Delete rows
                    </el-button>
                    <el-button
                      type=""
                      class="px-2 py-2"
                      @click="deleteEnabled = false"
                    >
                      Cancel
                    </el-button>
                  </div>
                </div>
              </el-col>
            </el-row>
          </el-form>
        </div>
      </div>
      <div class="el-drawer--footer is-align-center">
        <el-button plain @click="closeEvent">Cancel</el-button>
        <el-button type="primary" :loading="adding" @click="add">Add</el-button>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import business from "@/requests/business";
import * as actions from "@/store/action-types";

export default {
  name: "CorporateEmployeesForm",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    company: {
      type: Object,
      default() {
        return {};
      },
    },

    subscriptions: {
      type: Array,
      default() {
        return [];
      },
    },

    subscriptionId: {
      type: [String, Number],
    },
  },

  data() {
    return {
      loading: false,
      form: [
        {
          first_name: "",
          last_name: "",
          email: "",
          deleteSelected: false,
        },
      ],
      adding: false,
      subscription_id: "",
      deleteEnabled: false,
      corporateMenuType: "",
    };
  },

  computed: {
    shouldShow: {
      get() {
        return this.show;
      },

      set(value) {
        this.$emit("update:show", value);
      },
    },
    locations() {
      return this.$store.getters.location_areas_list;
    },
    gardeners() {
      const gardeners = [...new Set(this.$store.getters.gardeners_list)];
      return this.sortList(gardeners, "name");
    },
  },
  watch: {
    show() {
      if (this.show) {
        this.loading = true;
        // this.corporateMenuType = {
        //   name: this.company.menu_type_variation.name,
        //   id: this.company.menu_type_variation.id,
        // };
        this.$store.dispatch(actions.GET_LOCATION_AREAS_LIST);
        this.$store.dispatch(actions.GET_GARDENERS_LIST).then(() => {
          this.form.location_area_id = this.company.location_area_id;
          this.form.address = this.company.company_address;
          this.form.plan = this.company.plan;
          this.form.user_corporation_id = parseInt(this.$route.params.id);
          this.loading = false;
        });
      }
    },
  },
  methods: {
    closeEvent() {
      this.shouldShow = false;
      this.$refs.form.resetFields();
      this.form = [
        {
          first_name: "",
          last_name: "",
          email: "",
          deleteSelected: false,
        },
      ];
    },
    disabledDates(time) {
      if (this.allowAccessFor(["superadmin"])) {
        return false;
      }
      return (
        time.getTime() < new Date(new Date().getTime() + 24 * 60 * 60 * 1000)
      );
    },

    deleteRows() {
      this.form = this.form.filter((elm) => !elm.deleteSelected);
      this.deleteEnabled = false;
    },
    addRow() {
      if (this.form.length == 10) return;
      this.form.push({
        first_name: "",
        last_name: "",
        email: "",
      });
    },
    setPlan({ config, amount }) {
      this.form.services = Object.keys(config);
      this.form.plan = config;
      this.form.amount = amount;
    },
    formatForm() {
      const payload = { ...this.form };
      payload.full_name = payload.first_name + " " + payload.last_name;
      payload.plan = JSON.stringify(payload.plan);
      return payload;
    },
    add() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) {
          return false;
        }
        this.adding = true;
        let id = this.subscriptionId
          ? this.subscriptionId
          : this.subscription_id;
        const payload = { employees: this.form };

        business
          .addEmployeesSingle({ id, payload })
          .then((response) => {
            if (response.data.status) {
              this.closeEvent();
              this.$emit("success");
              this.$message.success("Employee(s) was added successfully");
              this.adding = false;
            } else {
              this.$message.error(response.data.message);
              this.adding = false;
            }
          })
          .catch((error) => {
            this.adding = false;
            const errorMessage = error.response.data;
            if (errorMessage.errors) {
              const errorKeys = Object.keys(errorMessage.errors);
              this.$message.error({
                message: errorMessage.errors[errorKeys[0]].join(""),
                duration: 4000,
              });
            } else {
              this.$message.error({
                message: errorMessage.message,
                duration: 4000,
              });
            }
          });
        return true;
      });
    },
  },
};
</script>
