<template>
  <div>
    <div class="corporate--summary">
      <eden-information-card :title="'General Information'">
        <template slot="content">
          <el-table :class="'general'" :data="general" :show-header="false" >
            <el-table-column>
              <template slot-scope="scope">
                <!-- <template v-if="scope.$index < 3"> -->
                  <span class="label font-xsm">{{ scope.row.label }}</span>
                  <p class="text font-sm">
                    {{ scope.row.text }}
                  </p>
              </template>
            </el-table-column></el-table>
        </template>
      </eden-information-card>

      <eden-information-card :title="'Account Information'">
        <template slot="content">
          <el-table :data="account" :show-header="false">
            <el-table-column>
              <template slot-scope="scope">
                <section class="is-flex justify-between">
                  <div class="">
                    <span class="label font-xsm">{{ scope.row.label }}</span>
                    <p v-if="scope.row.text" class="text font-sm">
                      {{ scope.row.text }}
                    </p>
                    <p v-else>-</p>
                  </div>
                  <el-button type="text" v-if="scope.row.action" @click="showAdminModal = true"> {{ scope.row.action.label
                  }} </el-button>
                </section>
              </template>
            </el-table-column></el-table>
        </template>
      </eden-information-card>
    </div>

    <customer-corporate-subscription :subscriptions="subscriptions" />
    <account-admins :shouldShow="showAdminModal" @close="showAdminModal = false" :accountAdmins="data.admins"/>
  </div>
</template>

<script>
import AccountAdmins from "./CorporateAccountAdmins.vue";
import CustomerCorporateSubscription from "./CustomerCorporateSubscription.vue";

export default {
  components: { CustomerCorporateSubscription, AccountAdmins },
  name: "CustomerCorporateSummary",
  props: {
    data: {
      type: Object,
      default() {
        return {};
      },
    },
    subscriptions:{
      type: Array,
      default() {
        return [];
      },
    }
  },

  data() {
    return {
      showAdminModal: false,
      contact: [
        {
          label: "Account Admin(s)",
          text: "3 Admins",
          action: {
            label: 'View admins',
            components: 'AccountAdmins'
          }
        },
        {
          label: "Primary Gardener",
          text: this.data.contact_person_email_address || "-",
        },
        {
          label: "Secondary Gardneer",
          text:
            this.formatPhoneNumber(this.data.contact_person_phone_number) ||
            "-",
        },
      ],
      subscription: {
        amount: this.data.subscription_amount,
        plan: { ...this.data.plan },
        menuType: this.data.menu_type,
      },

      accountAdmins: [
        { name: "Micheal Olalekan", email: 'michealoalekan#gmail.com', position: "HR" },
        { name: "Micheal Olalekan", email: 'michealoalekan#gmail.com', position: "HR" },
        { name: "Micheal Olalekan", email: 'michealoalekan#gmail.com', position: "HR" },
      ]
    };
  },
  computed: {
    general() {
      return [
        {
          label: "Address",
          text: this.data.address || "-",
        },
        {
          label: "Joined on",
          text:
            this.formatDateAndTime(this.data.created_at, "do m, y") || "-",
        },
        {
          label: "Last updated",
          text:
            this.formatDateAndTime(this.data.updated_at, "do m, y") || "-",
        },
        {
          data: [
            {
              label: "Joined on",
              text:
                this.formatDateAndTime(this.data.created_at, "do m, y") || "-",
            },
            {
              label: "Last updated",
              text:
                this.formatDateAndTime(this.data.updated_at, "do m, y") || "-",
            },
          ],
        },
      ];
    },

    account(){
      return  [
        {
          label: "Account Admin(s)",
          text: this.data.admins? this.data.admins.length + " Admin(s)" : "--",
          action: {
            label: 'View admins',
            components: 'AccountAdmins'
          }
        },
        {
          label: "Primary Gardener",
          text: this.data.primary_gardener?.name || "-",
        },
        {
          label: "Secondary Gardneer",
          text:
            this.formatPhoneNumber(this.data.seconday_gardener) ||
            "-",
        },
      ]
    }
  },
  watch: {
    data() {
    },
  },
  methods: {
    arraySpanMethod({ rowIndex }) {
      if (rowIndex === 2) {
        return [1, 2];
      } else if (rowIndex === 3) {
        return [3, 4];
      } else {
        return [1, 4];
      }
    },
  },
};
</script>

<style lang="scss">
.corporate--summary {
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
}

.general {
  tr:last-child {
    td {
      padding: 0;

      .cell {
        padding: 0;
      }
    }
  }
}

.inner-table {
  tbody {
    display: flex;

    tr {
      width: 50%;
      padding: 16px;

      &:nth-child(2) {
        border-left: 1px solid #ebeef5;
      }
    }
  }
}

.el-table .el-table__cell {
  padding: 18px 0;
}

@media (min-width: 768px) {
  .information-card {
    margin-bottom: 0 !important;
  }

  .information-card:first-child {
    border-radius: 0;
  }

  .information-card:nth-child(2) {
    border-left: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
}

@media (min-width: 996px) {
  .corporate--summary {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}
</style>