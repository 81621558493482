<template>
  <section>
    <div class="corporate-subscription">
      <div style="width: 70%">
        <span class="font-xsm text-grey-tertiary">Subscription Group(s)</span>
        <div class="is-flex gap list">
          <p v-for="(item, idx) in subscriptions" :key="item.name" class="font-sm" v-html="getText(subscriptions,item, idx)"> 
          </p>
        </div>

      </div>
      <el-button type="text" @click="viewSubscriptionsGroups" v-if="subscriptions.length">
        View subscription group(s)</el-button>
    </div>
  </section>
</template>

<script>
export default {
  name: "CustomerCorporateSubscription",
  props: {
    subscriptions: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      service: {},
      ActiveSubs: ["GM Suite", "HR", "Mid-level"]
    };
  },
  mounted() {
  },

  computed:{
    getSubscriptionNames(){
      return this.subscriptions.filter(elm => elm.status === 'active')
    }
  },
  methods: {
    getText(name, item, idx){
      return name.length > 0 ? name.length > idx + 1 ? item.name + ","+"&nbsp;"  : item.name : "--" 
    },
    viewSubscriptionsGroups() {
      this.$router.push({ name: "business.corporate-subscriptions" , query: {company_name: this.$route.query?.company_name}});
    },
  },
};
</script>

<style lang="scss" scoped>
.corporate-subscription {
  padding: 17px 16px;
  border-bottom-right-radius: 16px;
  border: 1px solid #ebeef5;
  border-top: 0;
  width: 320px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  color: var(--eden-grey-secondary);

  .list{
    // width: 60%;
    flex-wrap: wrap;
  }
  span.font-xsm {
    margin-bottom: 4px;
    display: block;
  }

  &--amount {
    margin-left: 20px;
    display: inline-block;
    color: var(--eden-grey-primary);
    font-weight: 500;
  }

  @media (min-width: 850px) {
    width: 100%;
  }
}
</style>