<template>
    <div>
        <el-drawer :title="title" :visible.sync="setShow" direction="rtl" append-to-body @close="closeEvent">
            <div class="el-drawer--content">
                <div class="el-drawer--content__body">
                    <eden-loader v-if="loading" />
                    <el-form v-else :model="form" label-position="top" ref="form">
                        <el-row type="flex" :gutter="15" class="is-flex-wrap">
                            <el-col :md="24">
                                <el-form-item label="Select subscription" :rules="validateField()">
                                    <el-select v-model="form.subscription_id" filterable placeholder="Select subscription">
                                        <el-option v-for="sub in subscriptions" :key="sub.id" :label="sub.name"
                                            :value="sub.id"></el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row type="flex">
                            <el-col :span="24">
                                <el-form-item label="Payment Type" prop="payment_type" :rules="validateField()">
                                    <el-radio-group v-model="form.payment_type" class="eden-custom--radio flex"
                                        @change="getSubscription">
                                        <el-radio label="subscription">Subscription</el-radio>
                                        <el-radio label="one-off">One-off</el-radio>
                                    </el-radio-group>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row type="flex">
                            <el-col :span="24">
                                <el-form-item label="Transaction date" prop="date_paid" :rules="validateField()">
                                    <el-date-picker v-model="form.date_paid" type="date" format="dd MMM, yyyy"
                                        value-format="yyyy-MM-dd" placeholder="Select transaction date"
                                        @change="setTransactionRef">
                                    </el-date-picker>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row v-if="action === 'add'" type="flex">
                            <el-col :span="24">
                                <el-form-item label="Transaction Ref" prop="tnx_reference" :rules="validateField()">
                                    <el-input type="text" v-model="form.tnx_reference" />
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row type="flex">
                            <el-col :span="24">
                                <el-form-item label="Payment Channel" prop="payment_channel" :rules="validateField()">
                                    <el-radio-group v-model="form.payment_channel" class="eden-custom--radio flex"
                                        @change="setPaymentGateway">
                                        <el-radio label="card">Card</el-radio>
                                        <el-radio label="bank_transfer">Bank</el-radio>
                                        <el-radio label="barter">Barter</el-radio>
                                        <el-radio v-if="location === 'KE'" label="mpesa">MPESA</el-radio>
                                    </el-radio-group>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row type="flex">
                            <el-col :span="24">
                                <el-form-item label="Payment Gateway" prop="payment_gateway" :rules="validateField()">
                                    <el-radio-group v-model="form.payment_gateway" class="eden-custom--radio flex"
                                        :disabled="fixedPaymentGateway">
                                        <el-radio :disabled="form.payment_channel === 'mpesa'"
                                            label="paystack">Paystack</el-radio>
                                        <el-radio label="flutterwave">Flutterwave</el-radio>
                                        <el-radio v-if="form.payment_channel === 'bank_transfer'"
                                            label="direct_bank_transfer">Direct Bank Transfer</el-radio>
                                        <el-radio v-if="form.payment_channel === 'mpesa'" label="paybill">Paybill</el-radio>
                                    </el-radio-group>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row v-if="generateInvoice" type="flex">
                            <el-col :span="24">
                                <el-form-item label="Name of customer" prop="customer_name" :rules="validateField()">
                                    <el-select v-model="form.customer_name" filterable allow-create
                                        placeholder="Select or enter customer name">
                                        <el-option v-for="(customer, index) in customers" :key="index"
                                            :label="formatName(customer.name)" :value="customer.name.toLowerCase()">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <!-- <el-row>
                            <el-col>
                                <el-form-item label="Select services" prop="service" :rules="validateField()">
                                    <el-checkbox-group v-model="form.service"
                                        class="eden-custom--checkbox flex pick-service" :min="1">
                                        <el-checkbox v-for="(service, index) in services" :key="index"
                                            :label="service.service_name.toLowerCase()">{{ service.service_name
                                            }}</el-checkbox>
                                    </el-checkbox-group>
                                </el-form-item>
                            </el-col>
                        </el-row> -->
                        <!-- <payment-breakdown :payment-type="form.payment_type" :services="form.service"
                            :breakdown.sync="form.break_down" :amount.sync="form.amount" /> -->
                        <el-row type="flex" class="mt-20">
                            <el-col :span="24">
                                <el-form-item label="Total Amount" prop="amount">
                                    <el-input v-model.number="form.amount" />
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-form>
                </div>
            </div>
            <div class="el-drawer--footer is-align-center">
                <template v-if="generateInvoice">
                    <el-button plain @click="closeEvent">Cancel</el-button>
                    <el-button type="primary" @click="preview">Preview Invoice</el-button>
                </template>
                <template v-else>
                    <el-button plain @click="closeEvent">Cancel</el-button>
                    <el-button v-if="action === 'edit'" type="primary" :loading="updating" @click="update">Save
                        changes</el-button>
                    <el-button v-else type="primary" :loading="adding" @click="add">Add</el-button>
                </template>
            </div>
        </el-drawer>
        <transaction-preview :show.sync="showTransactionInvoice" :data="transactionInvoice" :action="'generate-invoice'"
            @success="closeInvoice" />
    </div>
</template>
  
<script>
import TransactionPreview from "@/components/Subscriptions/Transactions/Actions/TransactionPreview";
import transactions from "@/requests/subscriptions/transactions";
import subscriptions from "@/requests/subscriptions";
import customers from "@/requests/customers/individual";
import subscriptionsRequest from "@/requests/business/subscription";
// import PaymentBreakdown from "@/components/Subscriptions/Transactions/Breakdown/PaymentBreakdown";

export default {
    name: "TransactionForm",
    components: {
        TransactionPreview,
        // PaymentBreakdown,
    },
    props: {
        action: {
            type: String,
            default: "add",
        },
        show: {
            type: Boolean,
            default: false,
        },
        transaction: {
            type: Object,
            default() {
                return {};
            },
        },
        generateInvoice: {
            type: Boolean,
            default: false,
        },
        email: {
            type: String,
            default: "",
        },
        customer: {
            type: Object,
            default() {
                return {};
            },
        },
    },
    data() {
        return {
            fetching: false,
            adding: false,
            updating: false,
            form: {
                user_email: "",
                date_paid: "",
                tnx_reference: "",
                payment_channel: "",
                payment_gateway: "",
                payment_type: "subscription",
                customer_name: "",
                services_with_frequencies: {},
                amount: null,
                break_down: [],
            },
            customers: [],
            frequencies: {
                "daily": "Daily",
                "weekly-twodays": "Twice a week",
                "weekly-threedays": "Three times a week",
                "weekly": "Weekly",
                "bi-weekly": "Every two weeks",
                "monthly": "Monthly",
                "quarterly": "Quarterly",
                "yearly": "Yearly",
            },
            fixedPaymentGateway: false,
            showServiceFrequency: false,
            transactionInvoice: {},
            showTransactionInvoice: false,
            loading: false,
        };
    },
    computed: {
        setShow: {
            get() {
                return this.show;
            },
            set(value) {
                this.$emit("update:show", value);
            },
        },
        subscriptions(){
            return this.$store.getters.get_active_subscription
        },
        company_id(){
            return this.$route.params.id
        },
        location() {
            return this.$store.getters.location;
        },
        services() {
            return this.$store.getters.services;
        },
        title() {
            return `${this.generateInvoice
                    ? "Generate Invoice"
                    : this.action === "edit"
                        ? "Edit Transaction"
                        : "Add Transaction"
                }`;
        },
        totalAmountFormat: {
            get() {
                return this.formatPrice(this.form.amount);
            },
            set(newAmount) {
                return newAmount;
            },
        },
    },
    watch: {
        show() {
            if (this.show) {
                if (this.generateInvoice) {
                    this.getCustomersList();
                }
                if (this.action === "add") {
                    const plan =
                        this.customer.next_billing_date?.length &&
                        this.customer.next_billing_date[0].plan;
                    if (plan !== undefined) {
                        const services = Object.keys(plan);
                        if (services.length) {
                            this.setServicesBreakDown(plan);
                        }
                    }
                }
                if (this.action === "edit") {
                    Object.keys(this.form).forEach((key) => {
                        if (key === "break_down") {
                            this.form[key] = this.parseData(this.transaction[key]) || {};
                            return;
                        }
                        this.form[key] =
                            this.transaction[key] ||
                            (typeof this.form[key] === "string" ? "" : {});
                    });
                    this.form.id = this.transaction.id;
                } else {
                    this.form.user_email = this.email ?? "";
                }
            }
        },
    },
    methods: {
        getCustomersList() {
            customers.customersList().then((response) => {
                if (response.data.status) {
                    this.customers = response.data.data.users;
                    console.log(this.customers);
                }
            });
        },
        getSubscription() {
            if (
                this.action === "edit" ||
                this.form.payment_type !== "subscription" ||
                this.form.break_down.length
            ) {
                return;
            }

            if (!this.form.user_email) {
                return;
            }

            this.loading = true;
            subscriptions
                .get(this.form.user_email)
                .then((response) => {
                    const { status, data } = response.data;
                    if (status && data.length) {
                        const plan = data[0].plan;
                        if (Object.keys(plan).length) {
                            this.setServicesBreakDown(plan);
                        }
                    } else {
                        this.$message.error(response.data.message)
                        this.form.service = [];
                    }
                    this.loading = false;
                })
                .catch((error) => {
                    const errorMessage = error.response.data;
                    if (errorMessage.errors) {
                        const errorKeys = Object.keys(errorMessage.errors);
                        this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
                    } else {
                        this.$message.error(errorMessage.message);
                    }
                    this.form.service = [];
                    this.loading = false;
                });
        },
        setServicesBreakDown(plan) {
            let total = 0;
            this.form.service = Object.keys(plan);
            this.form.break_down = this.form.service.map((service) => {
                total += plan[service].amount ? plan[service].amount : 0;
                const config = plan[service];
                return {
                    service: {
                        name: service,
                    },
                    amount: config.amount || null,
                    item: config.item,
                    frequency: this.formatText(config.frequency) || null,
                    service_day: config.service_day,
                    home_type: config.home_type,
                    quantity: config.qty,
                };
            });
            this.form.amount = total;
        },
        closeEvent() {
            this.setShow = false;
            this.$refs.form.resetFields();
            this.$emit("close");
            this.form = {
                user_email: "",
                date_paid: "",
                tnx_reference: "",
                payment_channel: "",
                payment_gateway: "",
                payment_type: "",
                customer_name: "",
                service: [],
                services_with_frequencies: {},
                amount: null,
                break_down: [],
            };
        },
        closeInvoice(transaction) {
            this.$emit("success", transaction);
            this.closeEvent();
        },
        setTransactionRef() {
            if (this.form.date_paid) {
                const date = new Date(this.form.date_paid);
                this.form.tnx_reference = `EDEN-PAYMENT-${date.getDate()}${date.getMonth() + 1
                    }${date.getHours()}${date.getMinutes()}`;
            }
        },
        setPaymentGateway() {
            switch (this.form.payment_channel) {
                case "barter":
                    this.form.payment_gateway = "flutterwave";
                    this.fixedPaymentGateway = true;
                    break;
                case "bank_transfer":
                    this.form.payment_gateway = "direct_bank_transfer";
                    this.fixedPaymentGateway = true;
                    break;
                case "mpesa":
                    this.form.payment_gateway = "paybill";
                    this.fixedPaymentGateway = false;
                    break;
                default:
                    this.fixedPaymentGateway = false;
            }
        },
        formatForm() {
            const payload = JSON.parse(JSON.stringify(this.form));
            if (payload.payment_type === "one-off") {
                payload.service.forEach((service) => {
                    payload.services_with_frequencies[service] = "one-off";
                });
            }
            // payload.service = payload.service.toString().toLowerCase();
            // payload.break_down = this.form.break_down.map((item) => {
            //     const obj = {
            //         service: item.service.name,
            //         amount: item.amount,
            //         item: item.item,
            //         service_day: item.service_day,
            //         frequency: item.frequency,
            //         quantity: item.quantity,
            //     };

            //     if (item.service.name === "cleaning") {
            //         obj.home_type = item.home_type;
            //     }
            //     return obj;
            // });
                
          let subscription =  this.subscriptions.find((elm)=> elm.id == this.form.subscription_id)
          console.log(subscription);
          if(subscription){
            payload.user_email = subscription.customer_email
          }      

            return payload;
        },
        preview() {
            this.$refs.form.validate(async (valid) => {
                if (!valid) {
                    return false;
                }
                this.transactionInvoice = this.formatForm();
                this.transactionInvoice.action = this.action;
                this.showTransactionInvoice = true;
            });
        },
        add() {
            this.$refs.form.validate(async (valid) => {
                if (!valid) {
                    return false;
                }
                this.adding = true;
                let payload = this.formatForm();
                delete payload.services_with_frequencies;

                let sub_id = this.form.subscription_id;
            
                subscriptionsRequest
                    .pay_subscription({company_id:this.company_id, sub_id, payload})
                    .then((response) => {
                        if (response.data.status) {
                            this.$message.success(response.data.message);
                            this.adding = false;
                            this.$emit("success");
                            this.closeEvent();
                        } else {
                            this.$message.error(response.data.message);
                            this.adding = false;
                        }
                    })
                    .catch((error) => {
                        this.adding = false;
                        const errorMessage = error.response.data;
                        if (errorMessage.errors) {
                            const errorKeys = Object.keys(errorMessage.errors);
                            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
                        } else {
                            this.$message.error(errorMessage.message);
                        }
                    });
                return true;
            });
        },
        update() {
            this.$refs.form.validate(async (valid) => {
                if (!valid) {
                    return false;
                }
                this.updating = true;
                const payload = this.formatForm();
                transactions
                    .update(this.transaction.id, payload)
                    .then((response) => {
                        if (response.data.status) {
                            this.$message.success(response.data.message);
                            this.updating = false;
                            this.$emit("success", {
                                ...payload,
                                services: payload.service.split(","),
                            });
                            this.closeEvent();
                        } else {
                            this.$message.error(response.data.message);
                            this.updating = false;
                        }
                    })
                    .catch((error) => {
                        this.updating = false;
                        const errorMessage = error.response.data;
                        if (errorMessage.errors) {
                            const errorKeys = Object.keys(errorMessage.errors);
                            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
                        } else {
                            this.$message.error(errorMessage.message);
                        }
                    });
                return true;
            });
        },
    },
};
</script>
  
<style lang="scss" scoped>
.pick-service {
    margin-bottom: -20px;
}
</style>
  