<template>
    <div class="admin-card text-center">
        <div class="admin-avatar text-uppercase mx-auto " :style="avatarTheme">
            {{ adminProfile.name? adminProfile.name.charAt(0): "E" }}
        </div>
        <label class="ttext-uppercase"> {{ adminProfile.company_role }}</label>
        <p>{{ adminProfile.name }} </p>
        <small>{{ adminProfile.email }} </small>
    </div>
</template>

<script>
export default {
    props: {
        adminProfile: {
            type: Object,
            required: true
        },

        avatarTheme:{
            type: Object,
            required: true
        }
    },

}
</script>

<style scoped lang="scss">
.admin-card {
    min-width: 31%;
    background: #fff;
    border: 1px solid #8aa39839;
    padding: 20px;
    border-radius: 8px;

    .admin-avatar{
        width: 50px;
        height: 50px;
        border-radius: 30px;
        font-size: 18px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    label{
        color: #8AA398;
        font-weight: 400;
        margin-top: 10px;
        display: block;
    }

    p{
        font-weight: 600;
    }
}
</style>